/* lessfile: modal.less */

@modal-prefix: sol-menu-modal;
.@{modal-prefix}-mask {
  position: fixed;
  z-index: 9998;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0, 50%);
  display: table;
  transition: opacity 0.3s ease;
  overflow: scroll;
}


.@{modal-prefix}-wrapper {
  display: table-cell;
  vertical-align: top;
}

.@{modal-prefix}-container {
  .no-outline-focus;
  width: 325px;
  margin: 148px auto 0;
  margin-top: 0px;
  float: left;
	transition: opacity 0.3s ease;
  opacity: 1;
  background-color: @modal-menu-bg;
}
.@{modal-prefix}-close {
  .no-appearance;
  position: absolute;
  top: 24px;
  right: 45px;
	color: @white;
  font-weight: 300;
  font-size: 17px;  
	text-transform: uppercase;
	padding: 0;
	@media @md{
		top: 5px;
    right: 10px;
	}
  cursor: pointer;
  & i {
    display: block;
    margin-bottom: 5px;
    font-size: 44px;
    color: fadeout(@white, 9%);
  }
}

.@{modal-prefix}-transition-enter {
  opacity: 0;
}
.@{modal-prefix}-transition-leave-active {
  opacity: 0;
}
.@{modal-prefix}-transition-enter .@{modal-prefix}-container {
  opacity: 0;
}
.@{modal-prefix}-transition-leave-active .@{modal-prefix}-container {
  opacity: 0;
}