.sol-article-filter {
  display: flex;
  margin-bottom: 20px;

  @media @sm {
    display: block;
  }

  &__text {
    font-size: 19px;
    padding-right: 10px;
    padding-top: 15px;
    @media @sm {
      margin-bottom: 10px;
    }
    & i.fa,
    & i.far,
    & i.fal,
    & i.fab,
    & i.fas {
      margin-right: 5px;
      font-size: 22px;
    }
  }
  &__select {
    flex: 1;
    display: flex;
    @media @sm {
      display: block;
    }
  }
  select {
    .input-select;
    margin-right: 10px;
    background-color: @button-bg;
    color: @button-text;
    border-radius: @border-radius-normal;
    height: 50px;
    border-color: @button-border;
    background-image: url('@{sol-icons-path}/form/chevron-down-neg.svg');
    background-position: right 15px top 17px;
    @media @sm {
      margin: 0 0 10px 0;
    }
    &:hover, &:focus {
      background-color: @button-bg-hover;
      color: @button-text; 
    }
  }
  .sol-clear-filter {
    .btn-smaller;
    padding: 0;
    flex: 0 0 40px;
    @media @sm {
      display: block;
      width: 100%;
    }
    & i.fa,
    & i.far,
    & i.fal,
    & i.fab,
    & i.fas {
      margin-right: 0;
      font-size: 19px;
    }
  }
}

.sol-clear-filter {
  &.font-btn {
    .no-appearance;
    position: relative;
    display: inline-block;
    line-height: 22px;
    padding: 10px 25px;
    color: #fff;
    border-radius: 4px;
    background-color: #454748;
    box-shadow: inset 0 -2px 0 0 transparent;
    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: ease;
    cursor: pointer;
    text-decoration: none;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Brix Sans', sans-serif;
    border: 0;
    padding-bottom: 12px;
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.sol-show-more {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  button {
    .btn;
  }
}
