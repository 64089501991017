
.sv-text-portlet .sv-responsiveTable {
  overflow-x: scroll;
}
.sv-text-portlet table.sol-table-decoration {
  border-collapse: collapse;
  border-radius: @border-radius-small;
  border-style: hidden;
  box-shadow: 0 0 0 1px @table-border;
  overflow: hidden;
  margin-bottom: 15px;

  & caption {
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 1.2em;
    font-weight: 800;
  }

  & thead {
    background-color: @thead-bg;

    & th {
      padding: 11px 18px;
      border-right: 1px solid @thead-border;

      & p {
        font-family: @main-font;
        font-size: 16px;
        line-height: 22px;
        color: @thead-color;
        font-weight: 400;
      }
      &:last-child {
        border: none;
        border-top-right-radius: @border-radius-small;
      }
      &:first-child {
        border-top-left-radius: @border-radius-small;
      }
    }
  }

  & tbody {
    background-color: @tbody-bg;

    & tr {
      td {
        border-right: 1px solid @tbody-border;
        padding: 11px 18px;

        & p {
          font-family: @main-font;
          font-size: 16px;
          font-weight: 300;
        }
        &:last-child {
          border: none;
        }
      }
      &:nth-child(odd) {
        background-color: @tbody-odd;
      }
    }
  }
}
