.sol-searchpage-wrapper {
  padding-top: 35px;
  padding-bottom: 50px;
  width: 100%;
  margin: 0 auto;
}


.sv-standardsearch-portlet,
.sv-facetedsearch-portlet {

  .sol-top-search {
    &-field {
      margin-left: 0;
      padding-left: 0;
      margin-top: 0.7em;
      list-style-type: none;
      width: 100%;
      padding-right: 0;
    }

    input[type=text] {
      border: 1px solid #e7e7e7;
      border-radius: 0;
    }

    button[type=submit] {
      border: 1px solid #e7e7e7;
      border-radius: 0;
      margin-right: 0;
    }
  }
  
  .sol-did-you-mean,
  .sol-num-hits {
    margin-top: 20px;
    width: 100%;
    font-size: 15px;
  }

  .sol-search-hits {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .sol-num-hits {
      margin-top: 0;
    }

    .font-btn {
      margin: 0 0 10px 0;
    }
  }

  .sv-search-facets {
      float: none;
      padding: 0 !important;
      margin-top: 0.7em;

      order: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media @sm {
        flex-direction: column;
      }
      > .sv-search-facet {
        margin-bottom: 20px;
        background-color: @bg-gray;
        width: 45%;
        position: relative;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        margin-left: 6px;
        margin-right: 6px;

        &:nth-child(1) {
          z-index: 5;
          margin-left: 0;
        }

        &:nth-child(2) {
          z-index: 4;
        }

        &:nth-child(3) {
          z-index: 3;
          margin-right: 0;
        }
        @media @sm {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
        > p {
          @media @md-strict {
            display: none;
          }
        }
        > button {
          .no-appearance;
          .focus-outline;
          position: relative;
          display: block;
          width: 100%;
          padding: 15px 50px 16px 15px;
          background-color: @tbody-odd;
          border-radius: 6px;
          font-family: @main-font;
          color: @black;
          text-align: left;
          text-decoration: none;
          line-height: 1.1;
          font-weight: 500;
          font-size: 18px;
          margin: 0;
          cursor: pointer;
          .sv-search-facet__button {
            display: block;
            width: 50px;
            height:50px;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -25px;
            background: @collapsible-bg;
            border-top-right-radius: @border-radius-small;
            border-bottom-right-radius: @border-radius-small;
            &::after,
            &::before {
              content: "";
              display: block;
              background-color: @white;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 20px;
              height: 4px;
              margin-left: -10px;
              margin-top: -3px;
              .transition(all);
            }
            &::after {
              .rotate(90deg);
            }
          }
          &.sol-active {
            background-color: @collapsible-bg;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            color: @white;
            & .sv-search-facet__button {
              &::after {
                .rotate(0deg);
              }
            }
          }
        }
        > ul {
          display: none;
          margin: 0;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
          padding: 14px 19px 24px 19px;
          position: absolute;
          top: 100%;
          left: 0;
          background-color: @white;
          width: 100%;
          z-index: -1;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          border-radius: 3px;
          > li {
            margin: 0 0 10px !important;
            a {
              position: relative;
              &::after {
                content: '';
                border-bottom: 2px solid @gray-2;
                position: absolute;
                left: -1px;
                right: -1px;
                bottom: 3px;
                z-index: -1;
              }
              &:hover {
                &::after {
                  border-bottom-color: #6ecde9;
                }
              }
            }
          }
        }
      }
    }

  .sv-search-result {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0.7em;
    list-style-type: none;
    width: 100%;
    padding-right: 0;

    .sv-search-hit {
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;
      padding: 20px 0 20px;
      border-radius: 2px;
      background-color: @search-hit-bg;
      border-top: 3px solid @bg-gray;

      & .font-heading-3 {
        display: block;
        margin-top: 0;
        margin-bottom: 3px;
        color: @search-hit-text;
        font-size: 20px;
        font-weight: 700;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: @search-hit-text-hover;
        }
      }

      & .font-normal {
        font-size: 15px;
      }

      & .sol-hit-info-text {
        font-size: 13px;

        & i.fal {
          color: @search-hit-icon;
          margin-right: 5px;
          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }
        }

        & > strong {
          margin-left: 10px;
        }

        & > strong:first-of-type {
          margin-left: 0;
        }
      }

      & .sol-hit-path-text {
        font-size: 15px;
        font-weight: 400;

        & a {
          color: @search-hit-path;
          text-decoration: none;

          &:hover {
            color: @search-hit-path-hover;
            text-decoration: underline;
          }
        }
      }

      .sv-column-8, .sv-column-4 {
        padding-left: 0;
        @media @sm {
          width: 100%;
          padding-left: 0 !important;
        }
      }

      .sv-column-4 {
        padding-left: 80px;
        @media screen and (max-width: 1300px) {
          padding-left: 30px;
        }
      }

      .font-btn {
        padding-top: 0;
        padding-bottom: 0;
        > a {
          display: inline-block;
        }
      }

    }
  }

  .sol-only-contacts {
    display: flex;
    flex-wrap: wrap;

    .sol-contactcard {
      &--hasImage {  
        li {
          width: 100% !important;
          word-break: break-word;
        }
  
      }
    }

  }

  .sol-contactcard {
    .font-heading-3 {
      margin-bottom: 20px !important;
      &:hover {
        text-decoration: none !important;
        color: initial !important;
      }
    }

    .font-heading-1, .font-heading-2 {
      font-size: initial !important;
    }

    .font-heading-3 + img {
      margin-right: 30px;
      vertical-align: top;

      @media @md {
        margin-right: 10px;
      }
    }

    ul {
      display: inline-block;
      padding: 0 0 5px 0;

      li {
        float: left;
        list-style: none;
        margin: 0 0 5px 0;
        width: 100%;
      }

      .sv-text-portlet-content {
        img {
          max-height: 180px !important; 
          max-width: initial !important;
        }
      }
    }

    &--noImage {
      ul {
        width: 100%;
  
        @media @md {
          width: 50% !important;
        }
  
        @media @md {
          float: initial;
        }
      }
    }

    &--hasImage {
      width: 50%;

    }

  }

  .sol-contact__list {
    margin: 0 0 20px 0;
    padding: 0 0 0 0 !important;
    display: flex;
    flex: none !important;

    @media @sm {
      width: 100% !important;
      
    }

    @media screen and (max-width:1000px) {
      min-height: 250px;
    }

    &:nth-child(even) {
      .sol-contactcard {
        @media screen and (min-width: 1000px) {
          margin: 0 0 0 52px;
        }

        @media screen and (max-width: 1000px) and (min-width: 768px) {
          margin: 0 0 0 40px;
        }
      }
    }

    .sol-contactcard {
      width: 100%;
      ul {
        li {
          font-size: 16px;
          margin: 0 0 0 0;
        }
      }

      &--hasImage {
        width: 60% !important;
      }
    }
  }

  .sv-search-facet-name {
    margin-top: 0;
    margin-bottom: 3px;
    color: @search-hit-text;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    display: none;
  }
  .sv-search-facet-item {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    color: #333333;
    font-weight: 400;
    margin-bottom: 8px;
    a {
      font-size: 17px;
      color: #333333;
    }
    &--has-matches {
      a {
        font-size: 17px;
        text-align: left;
        color: #204560;
      }
    }

  }
  .sv-search-facet-item-selected {
    a {
      &::after {
        display: block;
        content: '';
        border-bottom: 2px solid rgba(110, 205, 233, 0.6);
        position: absolute;
        left: -1px;
        right: -1px;
        bottom: 3px;
        z-index: -1;
      }
    }
  }

  .sol-pagination {
    text-align: center;

    .pagination {
      .sol-clearfix;
      display: inline-block;
      margin-top: 50px;

      & a,
      & span.current {
        width: 30px;
        height: 30px;
        margin-top: 0;
        margin-right: 2px;
        margin-left: 2px;
        padding: 4px 0 0;
        border-radius: 100%;
        background-color: @pagination-bg;
        box-shadow: none;
        color: @pagination-text;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        border: none;
        text-decoration: none;
        line-height: 22px;

        &:hover {
          background-color: @pagination-hover-bg;
        }
      }

      & span.current,
      & span.current:hover {
        font-weight: 700;
        background-color: @pagination-current-bg;
        color: @pagination-current-text;

        & strong {
          font-weight: 300;
        }
      }

      & > *:first-child:not(span),
      & > *:last-child:not(span) {
        border: none;
        width: auto;
        height: auto;
        border-radius: 0;
        font-size: 14px;
        padding: 4px 0 0;
        text-decoration: underline;
        margin-left: 15px;
        margin-right: 15px;

        &:hover {
          background-color: @pagination-bg;
          color: @pagination-current-bg;
        }
      }

      & span.current.next,
      & span.current.prev {
        display: none;
      }
    }
  }
}
