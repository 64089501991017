.sol-campaign {

  &-top-images {
    .sol-startpage-top-images-item {
      height: 60vh !important; 
    }
  }

  &-content {
    text-align: center;

    .sv-text-portlet p {
      font-size: 24px;
      max-width: 60ch;
      margin: auto;
    }

    .sv-image-portlet {
      padding: 20px 0;
    }

    &-top {
      position: relative;
      padding: 20px 0 20px 0;
      z-index: 1;

      &::after {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 60%;
        background-color: @cmp-color-1;
        content: '';
      }

      .sv-text-portlet h1,
      h2,
      h3,
      h4,
      p {
        color: #F5F9FC;
      }
    }

    &-middle {
      padding: 20px 0;
    }

    &-bottom {
      position: relative;
      padding: 20px 0 40px 0;
      z-index: 1;

      &::after {
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70%;
        background-color: @cmp-color-1;
        content: '';
      }

      .sv-text-portlet h1,
      h2,
      h3,
      h4,
      p {
        color: #F5F9FC;
      }
    }
  }

  &-blurb-row {
    display: flex;
    justify-content: space-evenly !important;
    min-width: 100%;
    margin: 0 !important;
    padding: 20px 0;

    &>.sv-layout {
      flex: 1 0 31%;
      border-radius: @widget-border-radius;
      background-color: @white;
      box-shadow: 10px 5px 20px 5px rgba(0, 0, 0, 5%);
      margin-right: 1%;
      margin-left: 1%;
    }

    .sol-widget-decoration {
      text-align: left;
      box-shadow: none;

      & .sol-widget-decoration-img {
        min-height: 230px;
        border-bottom: 5px solid @cmp-color-1;
      }

      & .sv-text-portlet {
        padding: 25px 20px 25px 35px;

        &:after {
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          font-family: 'Font Awesome 5 Pro';
          font-weight: 300;
          content: "\f105";
          position: absolute;
          top: 25px;
          left: 20px;
          right: auto;
          background-image: none;
          height: auto;
          font-size: 20px;
        }
      }
    }
  }

  &-slideshow {
    li {
      height: 600px;
    }
  }
}

.sv-carousel {
  padding: 20px 0;

  &__dots {
    display: none;
  }

  &__navbtn-prev,
  &__navbtn-next,
  &__dot {
      background-color: @cmp-color-1;
      opacity: 1;
  }

  &__dot--active {
    background-color: @alvdalen-purpur  !important;
  }
}

@media @sm {
  .sol-campaign-content-bottom {
    &::after {
      height: 100%;
    }
  }
  .sol-campaign-blurb-row {
    flex-direction: column;
    padding-top: 0;

    & > .sv-layout {
      margin-top: 20px;
    }

    .sol-widget-decoration {
      box-shadow: 10px 5px 20px 5px rgba(0, 0, 0, 25%);
    }
  }
  .sol-campaign-slideshow {
    li {
      height: 350px;
    }
  }
}

@media @xs {
  .sol-campaign-slideshow {
    li {
      height: 225px;
    }
  }
}
