.sol-contact,
.sol-related {
  background-color: @related-box-bg;
  margin-top: 5px;
  margin-bottom: 10px;
}
.sol-related {
  &-item {
    text-decoration: none;
    line-height: 23px;
    display: inline-block;
    border-bottom: 1px solid tint(@black,90%);
    padding: 10px 0;
    position: relative;
    &-name {
      text-decoration: underline;
      color: @related-box-link-text;
      .transition(color);
    }
    &-size {
      display: inline-block;
      color: @related-box-link-text;
      font-size: 12px;
      margin-left: 10px;
    }
    & i.fal {
      width: 17px;
      height: 17px;
      margin-right: 5px;
      border-radius: 50%;
      color: @related-box-icon;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
      position: absolute;
      top: 15px;
    }
    &:hover &-name {
      color: @related-box-link-hover;
    }
  }
  div &-item &-item-name {
    text-decoration: none;
    color: @related-box-text;
    display: inline-block;
    margin-left: 20px;
  }
  .sol-ul {
    li {
      border-bottom: 1px solid #E9E9E9;
    }
  }
}
.sol-contact {
  &-item {
    margin-bottom: 20px;

    &:not(:first-child) {
      padding-top: 10px;
      border-top: 2px solid @primary-color;
    }

    &:last-child {
      margin-bottom: 0;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 2px;
      font-size: 20px;
    }
    h4 {
      margin-top: 10px;
      display: block;
      margin-bottom: 0;
      font-size: 18px;
    }
    h5 {
      margin-top: 10px;
      display: block;
      margin-bottom: 0;
      font-size: 16px;
    }

    p,
    p.font-normal,
    dd,
    dl {
      margin-top: 0;
      margin-bottom: 5px;
      margin-left: 0;
      font-size: 17px;
      line-height: 1.2;

      a {
        color: @primary-color;
        text-decoration: none;

        span {
          text-decoration: underline;
        }

        &:hover span {
          color: @normal-link-hover;
        }
      }

      i.fal,
      i.far,
      i.fas {
        margin-right: 5px;
        width: 17px;
        text-align: center;
      }
    }

    dt {
      margin-top: 15px;
      font-weight: 500;
    }

    .sol-contact-img {
      max-height: 150px;
      margin-bottom: 10px;
    }
  }
}
.sol-related-contact {
  .sol-box-decoration-header {
    padding: 20px 30px;
    background: @button-bg;
    color: @button-text;
    border-top-left-radius: @border-radius-normal;
    border-top-right-radius: @border-radius-normal;
    margin-bottom: 0;
  }
  .sol-simple-box-decoration {
    background-color: tint(@bg-gray,50%);
    margin-top: 0;
    border-top: 0 none;
    padding: 20px 30px;
    border-bottom-left-radius: @border-radius-normal;
    border-bottom-right-radius: @border-radius-normal;
    .sol-related-item {
      i.fal {
        color: @button-bg;
      }
    }
  }
}