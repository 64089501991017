.sol-crisis-container {
  display: block;
  position: relative;
  width: 100%; 
  background-color: @bg-gray; //@crisis-bg;
  background-color: #fff026;
  background-color: @color-bg-crisis;
  color: @black; //@crisis-text;
  //border-bottom: 5px solid @bg-gray; //@color-bg-crisis ;
  .transition(height, 150ms, linear);

  @media @md {
    height: auto;
  }

  & .sv-row {
    position: relative;
    padding: 25px 25px 30px 155px; //25px 25px 20px 155px;
    display: inline-block;
    .transition(padding);
    @media @lg {
      padding: 25px 25px 30px 100px;
    }
    @media @md {
      padding: 20px 20px 20px 115px;
    }
    @media @sm {
      padding: 20px 20px 20px 60px;
    }
  }

  & i.fa-exclamation-triangle {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -55px;
    font-size: 70px; //100px;
    line-height: 100px; //110px;
    .transition(all);
    @media @md {
      left: 30px;
      top: 20px;
      margin-top: 0;
      font-size: 45px;
      line-height: 50px;
    }
    @media @sm {
      left: 20px;
      font-size: 25px;
      line-height: 30px;
    }
  }
  .sol-crisis-container {
    display: flex;
    flex-direction: column;
  }
  .sol-crisis-message {
    .sol-clearfix;
    display: flex; //inget alls
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    @media @sm {
      display: block;
    }
    &-title {
      display: block;
      flex: 0 0 100%; //inget alls
      margin-top: 0; //margin-bottom: 10px;
      margin-right: 20px; //inget alls
      margin-bottom: 10px;
      color: @black; //@crisis-text;
      font-size: 25px; //16px;
      font-weight: 700;
      text-decoration: none;
      @media @md {
        width: 100%;
        font-size: 20px;
      }
    }
    & p.font-description,
    & p.font-description a {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 0;
      @media @sm {
        font-size: 15px;
        line-height: 20px;
      }
    }
    &-desc {
      flex: 1 0 auto; //inget alls
      color: @black; //@crisis-text;
      line-height: 1.4em;
      max-width: 700px;
      margin-right: 30px; //inget alls
      margin-bottom: 0; //inget alls
      @media @lg-strict {
        max-width: 545px;
      }
      @media @md {
        margin-bottom: 10px;
      }
      @media @sm {
        font-size: 15px;
        line-height: 20px;
      }
    }

    &-more {
      //display: block;
      text-align: right;
      //min-width: 202px;
      flex-basis: 220px;
      @media @md {
        text-align: left;
      }

      & a {
        padding: 4px 8px;
        margin-right: 10px;
        border: 1px solid fadeout(@black, 90%); //@crisis-btn-border;
        border-radius: @button-border-radius;
        background-color: fadeout(@black, 90%); //@crisis-btn-bg;
        color: @black; //@crisis-text;
        font-weight: 500; //600;
        text-decoration: none;
        .transition(background-color);
        @media @sm {
          font-size: 14px;
        }

        &:hover {
          background-color: @crisis-btn-bg-hover;
        }
      }
    }
  }

  .sol-toggle-crisis {
    position: absolute;
    top: ~'calc(50% - 11px)'; //10px;
    right: 0px; //15px;
    z-index: 10;
    color: @black; //@crisis-text;
    cursor: pointer;
    font-weight: 500; //inget alls
    @media @md {
      font-size: 0;
      right: 7px;
      margin-top: 3px;
    }
    @media @sm {
      right: 7px;
    }

    & i.fal {
      margin-left: 10px;
      //opacity: 0.55;
      @media @md {
        font-size: 20px;
      }
    }
  }
  &.sol-crisis--small {
    height: 52px;
    & .sv-row {
      padding: 15px 25px 10px 155px;
    }
    & i.fa-exclamation-circle {
      font-size: 35px;
      line-height: 60px;
      left: 90px;
      margin-top: -29px;
      @media @md {
        margin-top: -24px;
      }
    }
    & .sol-crisis-message {
      margin-bottom: 10px;
      &-title {
        margin-bottom: 0;
      }
      & p {
        display: none;
      }
      &-more {
        display: none;
      }
    }
  }
}

.sol-no-script {
  i.fa-warning {
    font-size: 50px;
    margin-top: -57px;
  }

  .sv-row {
    padding: 15px 20px 0 90px;
  }

  .sol-crisis-message-title {
    margin-bottom: 5px;
  }
}
