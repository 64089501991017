.sol-cookie-message {
  .sol-clearfix;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 270px;
  background-color: @cookie-bg;
  color: @cookie-text;
  padding: 10px;
  font-size: 16px;
  min-height: 110px;

  & a {
    color: @cookie-text;
    text-decoration: underline;
  }
  & .sol-cookie-ok {
    .focus-outline;
    .no-appearance;
    display: inline-block;
    padding: 10px 50px;
    border-radius: @border-radius-normal;
    background-color: @cookie-ok-bg;
    color: @cookie-ok-text;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    cursor: pointer;
    float: right;
    margin-top: 8px;
    margin-right: 20px;
    border: 1px solid fadeout(@white,50%);
  }
  & .sol-cookie-message-content {
    max-width: 780px;
    color: @cookie-text;
  }
}
.sol-editor-bottom {
  bottom: 60px !important;
}
