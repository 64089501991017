@brix-sans-path: '@{sol-font-path}/brix-sans';

@font-face {
  font-family: 'Brix Sans';
  src: url('@{brix-sans-path}/BrixSans-light.woff2') format('woff2'),
       url('@{brix-sans-path}/BrixSans-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Brix Sans';
  src: url('@{brix-sans-path}/BrixSans-regular.woff2') format('woff2'),
       url('@{brix-sans-path}/BrixSans-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Brix Sans';
  src: url('@{brix-sans-path}/BrixSans-regular.woff2') format('woff2'),
       url('@{brix-sans-path}/BrixSans-regular.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Brix Sans';
  src: url('@{brix-sans-path}/BrixSans-medium.woff2') format('woff2'),
       url('@{brix-sans-path}/BrixSans-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Brix Sans';
  src: url('@{brix-sans-path}/BrixSans-bold.woff2') format('woff2'),
       url('@{brix-sans-path}/BrixSans-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}