.button() {
  .focus-outline;
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 10px 25px;
  color: @button-text;
  border-radius: @button-border-radius;
  background-color: @button-bg !important;
  box-shadow: inset 0 -2px 0 0 @button-box-shadow;
  .transition(~  'background-color, color');
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  font-family: @heading-font;
  border: 0;
  &:hover {
    background-color: @button-bg-hover !important;
    color: @button-text-hover;
  }
  &:active {
    top: 1px;
    box-shadow: inset 0 -1px 0 0 @button-box-shadow;
  }
  & i.fal {
    margin-right: 0px;
    margin-left: 10px;
    font-weight: 400;
  }
}
.button-small() {
  padding: 10px 25px 10px;
  color: @button-small-text;
  background-color: @button-small-bg;
  box-shadow: none;
  font-size: 16px;
  line-height: 10px;
  font-weight: 400;
  text-transform: none;
  &:hover {
    background-color: @button-small-bg-hover;
    color: @button-small-text-hover;
  }
  &:active {
    top: 0;
    box-shadow: none;
  }

  [data-whatinput="keyboard"] &:focus {
    outline: 2px solid @white;
    z-index: 10;
    outline-offset: 0;
    box-shadow: 0 0 0 4px @focus-border;
  }
}
