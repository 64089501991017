.sol-landing-page {
  background: @sol-landing-bg;
  
  h1, h1.font-heading-1 {
    text-align: center;
    margin: 30px 0 20px 0;
  }

  .pagecontent {
    @media @sm {
      width: 100%;

      .sol-widget-decoration {
        margin-bottom: 30px;
      }
    }
    
  }
}
