.sol-btn {
  .button;
}
.font-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  & > a {
    .button;
    padding-bottom: 12px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.sol-btn-small {
  .button-small();
}
.sv-text-portlet.sol-font-btn-small {
  .font-btn {
    text-align: right !important;
    padding-top: 4px;
    padding-bottom: 5px;
    margin-bottom: 20px;

    & > a {
      background-color: transparent !important;
      color: @black;
      font-size: 18px;

      &::after {
        position: absolute;
        right: 6px;
        top: 6px;
        color: @primary-color;
        font-size: 30px !important;
        font-weight: 100 !important;
        line-height: 1;
        .icon(@fa-var-angle-right, 16px);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
