
.sol-icon {
  &__tipsa {
    margin-right: 12px;
    max-height: 27.6px;
    &::after {
      height: 27.6px;
      width: 29.5px;
    }
  }
}
.sol-event-page {
  .font-heading-1 {
    &::after {
      display: none;
    }
  }
  &__create-event-btn {
    .button;
    margin-bottom: 20px;
    &__icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      position: absolute;
      width: 40px;
      height: 40px;
      right: 4px;
      top: 50%;
      border-radius: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}
