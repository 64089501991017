.sol-subpage-wrapper {
    .sol-widget-decoration {
        //background-color: transparent;
        //box-shadow: 0 2px 2px fade(@black,5%);
       
        .sv-image-portlet {
          margin-bottom: 0;
          img {
            border-radius: 0;
          }
        }
        .sv-text-portlet {
          padding: 25px 20px 25px 35px;
          background-color: @white;
          position: relative;
          border-top: 5px solid @primary-color;
          &:after {
            .fal;
            content: @fa-var-angle-right;
            position: absolute;
            top: 25px;
            left: 20px;
            right: auto;
            background-image: none;
            height: auto;
            font-size: 20px;
          }
        }
        &:hover {
            .sol-widget-decoration-img:before {
              opacity: 0.5;
            }
        
            .font-widget-heading {
              color: @widget-heading-text-hover;
              text-decoration: underline;
            }
          }
    }
}