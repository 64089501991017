.sol-feedback {
    background-color: @bg-gray;
    padding-top: 20px;
    width: 100%;
    & span {
        &:first-child {
            font-size: 23px;
            font-weight: bold;
        }
        margin-right: 16px;
        color: #333333;
    }
    & textarea {
        width: 100%;
        margin: 8px 0px;
        padding: 10px;
        font-size: inherit;
        // height: 70px;
        resize: vertical;
    }
    & button {
        .button();
        background-color: tint(@black,10%);
        &.sol-js-feedback-comment {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding: 15px 25px;
        }
    }
    & form {
        //text-align: center;
        margin-bottom: 20px;
    }
    &__content {
        display: table;
        margin: 0 auto;
    }

    &__answers {
        @media @md, @sm {
            margin-bottom: -2px;
        }
    }
    &__buttons {
        display: inline-block;
    }
}
