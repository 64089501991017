.sol-landing-page__widgets {
  background: @bg-gray;
  padding: 30px 0;
  @media @sm {
    padding-bottom: 0;
  }
  .pagecontent {
    width: 100%;
    max-width: 1170px;
    margin: auto;
    & > .sv-layout {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      @media @sm {
        flex-wrap: wrap;
      }
      & > .sv-layout {
        flex: 0 0 23%;
        border-radius: @widget-border-radius;
        background-color: @white;
        box-shadow: 0 2px 2px fade(@black, 5%);
        margin-right: 10px;
        margin-left: 10px;
        @media @sm {
          flex: 100%;
          margin-bottom: 20px;
          margin-left: 1px;
          margin-right: 1px;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
          @media @sm {
            margin-right: 1px;
          }
        }
        .sol-widget-decoration {
          background-color: transparent;
          box-shadow: none;
         
          .sv-image-portlet {
            margin-bottom: 0;
          }
          .sv-text-portlet {
            padding: 25px 10px 25px 27px;
            background-color: @white;
            position: relative;
            border-top: 5px solid @primary-color;
            &:after {
              .fal;
              content: @fa-var-angle-right;
              position: absolute;
              top: 25px;
              left: 15px;
              right: auto;
              background-image: none;
              height: auto;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
