footer.sv-layout {
  overflow: auto;
  background: @primary-color
    url("/webdav/files/System/resources/graphics/bergssiluett_rosa.svg") no-repeat
    50% 100%;
    padding-bottom: 20px;

  @media @sm {
    .sv-grid-main.sv-fluid-grid .sv-column-4,
    .sv-grid-main.sv-fluid-grid .sv-column-8 {
      width: 100%;
    }
  }

  .sol-bottom {
    & .sv-grid-main .sv-row {
      & > .sv-column-4 {
        padding-right: 30px;
      }
    }

    h3 {
      border-bottom: 2px solid white;
      color: white;
      font-size: 22px;
      margin-bottom: 10px !important;
      margin-top: 50px !important;
    }

    .sol-footer-social-media {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 10px 0;

      li {
        padding: 6px 6px;
        font-size: 35px;

        i {
          transition: 0.2s;
          color: white;
        }
      }

      &:hover {
        i {
          color: darken(@white, 10%);
        }
      }
    }
    &-phone {
      .font-normal a {
        &::before {
          .icon(@fa-var-phone, inherit, inline-block);
          margin: 0 10px 0 0;
        }
      }
    }

    &-mail {
      .font-normal a {
        &::before {
          .icon(@fa-var-envelope, inherit, inline-block);
          margin: 0 10px 0 0;
        }
      }
    }
  }

  .font-normal {
    color: white;
    font-size: 18px;

    a[rel="external"] {
      &::after {
        .icon(@fa-var-external-link, inherit, inline-block);
        margin-left: 10px;
        font-size: 12px;
      }
    }

    a {
      color: white;
      font-size: 18px;
      text-decoration: none;
      transition: 0.2s;

      &:hover {
        text-decoration: underline;
        color: white;
      }

      &:visited {
        color: white;
      }

      &:link {
        color: white;
      }

      &::before {
        .icon(@fa-var-chevron-right, inherit, inline-block);
        margin: 0 10px 0 0;
      }
    }
  }

  .env-button {
    background-color: white;
    border-radius: 5px;
    color: @primary-color;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 14px;
    margin: 10px 0;
    transition: 0.2s;

    &::before {
      .icon(@fa-var-thumbtack, inherit, inline-block);
      margin: 0 10px 0 0;
    }

    &:hover {
      color: @primary-color;
      background-color: darken(@white, 10%);
    }
  }
}
