.sol-startpage-shortcuts {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  width: 100%;
  flex-wrap: wrap;

  li {

  @media @md {
    display: flex;
    flex: 50%;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      max-width: 120px;
      transition: 0.2s;

      &:hover {
      i {
        background-color: darken(@primary-color,10%);
        text-decoration: none;
      }
      p {
        text-decoration: underline;
      }
      }

      i {
        border-radius: 50%;
        background-color: @primary-color;
        height: 95px;
        width: 95px;
        font-size: 45px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
      }

    }
  }
}
