.sol-print {
  color: @print-btn-text;
  font-size: 20px;
  font-weight: 600;
  float: none;
  //height: 45px;
  //padding-top: 7px;
  //padding-left: 35px;
  @media screen and (max-width: 380px) {
    padding-left: 5px;
  }
  & > button {
    .no-appearance;
    cursor: pointer;
    background: @button-bg;
    display: inline-block;
    border-radius: 23px;
    width: 44px;
    height: 44px;
    text-align: center;
    &:hover {
      text-decoration: underline;
    }
  }
  & > span {
    cursor: pointer;
    font-size: 0;
    background: @button-bg;
    display: inline-block;
    border-radius: 23px;
    width: 44px;
    height: 44px;
    text-align: center;
    padding-top: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
  & i.fal {
    //margin-right: 5px;
    font-size: 20px;
    color: @button-text;
  }
}
