.sol-pagination {
  &__list {
    display: flex;
    list-style: none;
    margin-top: 30px;
    align-items: center;
    padding-left: 25px;
    justify-content: flex-start;
    @media @md-strict {
      justify-content: flex-start;
      padding-left: 25px;
    }
    @media @md {
      justify-content: center;
    }
    &-item {
      > a {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &, &--previous, &--next, &--first, &--last {
        display: flex;
        margin-right: 20px;
        > a {
          font-size: 19px;
          text-align: left;
          color: @black;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            text-decoration: none;
            &::after {
              border-bottom-color: #6ecde9;
            }
          }
        }
        &.active {
          > a {
            border: solid 2px rgba(110, 205, 233, 0.6);
            border-radius: 50%;
          }
        }
      }
      &--previous, &--next, &--first, &--last {
        > a {
          font-size: 19px;
          font-weight: 300;
          text-align: left;
          color: #204560;
          text-decoration: none;
          position: relative;
          &::after {
            content: '';
            border-bottom: 2px solid rgba(110, 205, 233, 0.6);
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: 2px;
            z-index: -1;
          }
        }
      }
    }
  }
}
