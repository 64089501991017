.sol-startpage-article-item {
  display: block;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
  min-height: 120px;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;

  &.sol-no-img {
    padding-left: 0;
    min-height: auto;

    @media @sm {
      padding-top: 20px;
    }
  }

  & &-img {
    height: 200px;
    background-position: 50% 50%;
    background-size: cover;
    text-decoration: none;
    object-fit: cover;
    object-position: 50%;

    @media @sm {
      height: 180px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .transition(background-color);
      border-radius: @border-radius-normal;
    }
  }

  & &-date {
      color: tint(@black,35%);
      margin-bottom: 3px;
      font-weight: 400;
      font-size: 14px;

      &::before {
        .icon(@fa-var-clock, inherit, inline-block);
        font-size: 12px;
        margin-right: 8px;
      }
  }

  & &-heading {
    display: block;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
    font-family: @heading-font;
    line-height: 25px;
    .transition(color);
  }

  & &-desc {
    font-weight: 400;
  }

  & &-event-info {
    .sol-clearfix;
    margin-top: 10px;

    &-item {
      margin-right: 13px;
      font-size: 11px;
      float: left;

      & i {
        margin-right: 6px;
        color: @event-item-icon-bottom;
      }
    }
  }

  &:hover &-heading {
    color: @news-item-text-hover;
    text-decoration: underline;
  }
}

.sol-startpage-article-firstItem {
  margin: 0 5px;
  display: flex;

  a {
    min-height: auto;
    padding: 0;

    .sol-startpage-article-item-content {
      padding: 15px 10px;

      .sol-startpage-article-item-date {
        padding-top: 5px;
      }
    }
  }


  .sol-startpage-article-item-img {
    border-radius: 5px 5px 0 0;
  }
}

.sol-article-itemList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;

  @media @md {
    display: flex;
    flex-direction: column;
  }
}
