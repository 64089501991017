.sol-eservices {
  .env-button {
    background-color: #3C3E3F;
    margin: 15px 0;
    color: @white;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    justify-content: space-between;
    padding: 0 12px;
    font-weight: 400;
    transition: 0.2s;

    &::after {
      .icon(@fa-var-chevron-right, inherit, inline-block);
      font-size: 22px;
    }

    &:hover {
      background-color: lighten(#3C3E3F,10%);
    }
  }
}
