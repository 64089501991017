.btn-focus() {
  &,
  [data-whatinput='keyboard'] {
    &:focus {
      background-color: @gray-lighter;
      color: @black;
    }
  }
  [data-whatinput='initial'] &,
  [data-whatinput='mouse'] &,
  [data-whatinput='touch'] & {
    &:focus {
      color: @button-text-hover;
      background-color: @button-bg-hover;
    }
  }
}

.btn() {
  .no-appearance;
  .outline-focus;
  .btn-focus;
  display: inline-block;
  border-radius: 5px;
  color: @button-text;
  border: solid 2px @button-border;
  background-color: @button-bg;
  font-family: @main-font;
  font-size: 14px;
  font-weight: 700;
  min-width: 180px;
  min-height: 40px;
  line-height: 36px;
  padding: 0 18px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: @button-text-hover;
    background-color: @button-bg-hover;
  }
  & i.fa,
  & i.far,
  & i.fal,
  & i.fab,
  & i.fas {
    font-size: 16px;
    color: @button-text;
    margin-right: 8px;
    position: relative;
    top: 1px;
  }
}
.btn-smaller() {
  .btn;
  min-width: 0;
  padding: 6px 16px;
  min-width: 0;
  font-size: 13px;
  font-weight: 500;
  min-height: 33px;
  line-height: 33px;
  padding: 0 15px;
}
.btn-mini() {
  .no-appearance;
  .btn-focus;
  display: inline-block;
  background-color: @button-bg;
  color: @button-text;
  font-family: @main-font;
  font-size: 12px !important;
  font-weight: 600;
  padding: 0 10px 0;
  text-shadow: none;
  transition: none;
  min-height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  box-shadow: none;
  & i.fa,
  & i.far,
  & i.fal,
  & i.fab,
  & i.fas {
    margin-right: 5px;
  }
  &:hover {
    color: @button-text-hover;
    background-color: @button-bg-hover;
  }
  &.disabled,
  &[disabled] {
    background-color: @button-bg;
    opacity: 0.6;
  }
}
