.sol-pinboard {
  background-color: @tbody-odd;
  padding-left: 30px;
  border-radius: 8px;

  &-creator {

    &, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #a7a7a7;
    }

    &__registration-form {
      select {
        -webkit-appearance: menulist;
      }
  
      .sol-form-group {
        margin-top: 10px;
      }
  
      &.input-group--text-field.input-group--dirty.input-group--select label, .input-group--text-field.input-group--dirty:not(.input-group--textarea) label {
        transform: translateY(-28px) scale(.75);
        left: 40px !important;
      }
      
      .input-group--text-field {
        label {
          top: 8px;
          left: 50px !important;
        }
      }
      .menu__activator--active {
          label {
            top: 18px;
          }
      }
      .sol-form;
      .sol-event-form;
      label {
        font-weight: 400;
      }
      .sol-notice__dates {
        .application--wrap {
          background-color: #fff;
          padding: 0;
        }
  
        &__error-message {
          color: red;
          font-family: @main-font;
          line-height: 22px;
        }

        .menu__content {
          min-width: initial !important;
        }
      }
  
      .sol-authority__error-message {
        color: red;
      }
  
      .sol-title__error-message {
        color: red;
      }
  
      .sol-form-group__error-message {
        color: red;
      }
  
      .sol-files-list {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        li {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
      .sol-remove-file-btn {
        background-color: #bee9f5;
        padding: 5px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }
  
      .sol-file-uploader__upload-btn {
        .btn;
        margin: 10px 0 0 0;
      }
  
      button[type="submit"] {
        .btn;
      }
  
      .sol-file-uploader input {
        display: none;
      }  
  
      .input-group {
        height: 40px;
        padding: 0 0 0 0;
        margin: 23px 0 18px 0;
  
        &__details {
          display: none;
        }
      }
  
      .sol-pinboard-datePicker {
        max-height: 100px;
      }
  
      .application--wrap {
        min-height: auto;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-top: 0;
    }
  }

  .sol-pin {
    padding-top: 16px;
    padding-bottom: 16px;
    line-height: 30px;

    p {
      margin-bottom: 0;
    }
  }
}

.sol-collapsible-pinboard {
  margin-bottom: 20px;

  .sv-decoration-content {
    a {
      display: block;
      margin-bottom: 15px;
    }
  }
}

.sol-form-top-level-error-list{
  border-radius: 3px;
  margin-bottom: 1rem;
}
.sol-form-top-level-error-item {
  padding: 15px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  background-color: rgba(200, 73, 107, 0.2);
  letter-spacing: 0.4px;
  margin-bottom: 1rem;
  color: #333333;
}
