.sol-publish-info {
  font-size: 20px;
  font-weight: 300;
  & strong {
    margin-right: 5px;
    //font-size: 14px;
    @media @sm {
      font-size: 13px;
    }
    @media @xs {
      display: block;
    }
  }
}
