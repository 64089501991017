.icon-size(@height;
@width: @height) {
  max-height: @height;
  &::after {
    height: @height;
    width: @width;
  }
  &.sol-icon-edge {
    height: @height;
    width: @width;
    &::after {
      transform: scale(0.5);
      height: @height * 2;
      width: @width * 2;
      margin-top: -(@height / 2);
      margin-left: -(@width / 2);
    }
  }
}
.sol-icon {
  display: inline-block;
  &::after {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    display: inline-block;
    height: 15px; 
    width: 15px;
  }
}
.sol-required-asterisk {
  &:after {
    display: inline-block;
    content: "\002a";
    color: red;
    font-size: 14px;
  }
}
