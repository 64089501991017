/* subpage/page-listing.less */
.sol-page-listing {
  position: relative;
  ul.sol-row {
    .no-list-style;
    margin-right: 0 !important;
    margin-left: 0 !important;
    @media @sm {
      margin-left: 8px !important;
    }
  }
  &-item {
    margin-bottom: 10px;
    @media only screen and (max-width: 1150px) and (min-width: 767px) {
      max-width: 40% !important;
      display: inline-block;
      flex: 1 0 100% !important;
      &:nth-child(odd) {
        margin-left: 20%;
      }
    }

    @media @sm {
      max-width: 80% !important;
      display: block;
      flex: 1 0 100% !important;
      margin-left: 20px;
    } 

    &__link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &__name {
      color: inherit;
      margin-bottom: 12px;
      margin-top: 0;
      font-weight: 700;
    }
    &__icon {
      font-size: 17px;
      float: left;
      margin-right: 7px;
      height: 20px;
      width: 20px;
      color: @page-listing-item-icon-color;
      background-color: @page-listing-item-icon-bg;
      border-radius: 50%;
      text-align: center;
      position: relative;
      top: 7px;
      padding-left: 1px;
      padding-top: 1px;
      margin-left: -12px;
      font-weight: 400;
    }

    &__desc {
      @media @sm {
        display: none;
      }
    }
  }
  &-child {
    &__link {
      display: block;
      margin-bottom: 10px;
      font-size: 17px;
      color: @page-listing-child-color;
      text-decoration: none;
      .transition(color);
      &:hover {
        text-decoration: underline;
      }
      & i.fa-external-link {
        font-size: 11px;
        position: relative;
        top: -1px;
        margin-left: 5px;
      }
    }
    &__icon {
      font-size: 18px;
      float: left;
      margin-right: 8px;
      color: @page-listing-child-icon-color;
      position: relative;
      top: 2px;
      padding-left: 2px;
    }
  }
}
