.sol-top-nav {
  .sol-clearfix;
  display: inline-block;
  vertical-align: top;

  &-item {
    .focus-outline;
    float: left;
    padding: 10px 12px 12px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    color: @header-menu-text;
    font-size: 14px;
    line-height: 1.2em;
    font-weight: 700;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    vertical-align: top;
    text-decoration: none;
    max-width: 125px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .transition(background-color);
    &:first-child {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:hover {
      background-color: @header-menu-hover;
    }
    &.sol-active {
      background-color: @header-menu-active;
    }
  }
}
