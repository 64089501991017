.sol-startpage-widgets {
  background: @bg-gray;
  padding: 30px 15px;
  .pagecontent {
    width: 100%;
    & > .sv-layout {
      width: 100%;
      display: flex;
      @media @sm {
        flex-wrap: wrap;
      }
      & > .sv-layout {
        flex: 0 0 23%;
        margin-right: 1%;
        margin-left: 1%;
        @media @sm {
          flex: 100%;
          margin-bottom: 20px;
          margin-left: 1px;
          margin-right: 1px;
        }
        .sol-widget-decoration {
          background-color: transparent;
          box-shadow: 0 2px 2px fade(@black,5%);
          height: 100%;
          background-color: white;
         
          .sv-image-portlet {
            margin-bottom: 0;

            img {
              height: 170px;
              @media @md {
                height: 124px;
              }
              @media @sm {
                height: 250px;
              }
            }
          }
          .sv-text-portlet {
            padding: 25px 20px 25px 35px;
            background-color: @white;
            position: relative;
            border-top: 5px solid @primary-color;
            &:after {
              .fal;
              content: @fa-var-angle-right;
              position: absolute;
              top: 25px;
              left: 20px;
              right: auto;
              background-image: none;
              height: auto;
              font-size: 20px;
            }
          }
          &-wrapper {
            height: 100%;
          }

        }
      }
    }
  }
}
