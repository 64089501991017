.sol-event-search__tabs {
  display: flex;
  padding-left: 103px;
  border-bottom: 3px solid @color-2;
  margin-top: 25px;
  margin-bottom: 30px;
  @media @md-strict {
    padding-left: 0;
    justify-content: center;
  }
  @media @sm {
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 40px;
  }
  > .sol-event-search__tab {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 185px;
    height: 45px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border: solid 1px rgba(51, 51, 51, 0.11);
    text-decoration: none;
    font-size: 18px;
    span {
      margin-left: 5px;
    }
    &:first-child {
      margin-right: 9px;
    }
    &--active {
      background-color: #0e3c5d;
      color: @white;
    }
  }
}

