.sol-mobile-menu-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.3s ease;
}

.sol-mobile-menu-modal-close {
  display: none;
  .no-appearance;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  color: @white;
  cursor: pointer;
  padding: 0;
  & i {
    display: none;
    margin-bottom: 5px;
    font-size: 34px;
  }
}

.sol-mobile-menu-modal-container {
  .no-outline-focus;
  transition: opacity 0.3s ease;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: @color-1;
  padding: 15px 15px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.sol-mobile-menu-modal-transition-enter {
  opacity: 0;
}
.sol-mobile-menu-modal-transition-leave-active {
  opacity: 0;
}
.sol-mobile-menu-modal-transition-enter .sh-mobile-menu-modal-container {
  opacity: 0;
}
.sol-mobile-menu-modal-transition-leave-active .sh-mobile-menu-modal-container {
  opacity: 0;
}

