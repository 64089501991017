.sol-event-form-n-more-info-wrapper {
    .sol-pinboard-creator__registration-form select {
        border-radius: @border-radius-small;
        border: solid 1px @form-input-border;
        padding: 10px 15px;
        width: 100%;
    }
}  

.sol-error-message {
    display: none;
}

.sol-thankyou-message {
    display: none;
}
