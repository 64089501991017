.sol-section {
  padding-top: 35px;
  padding-bottom: 50px;
  max-width: 760px;
  margin: 0 auto;
  .sv-image-portlet,
  .sv-mfpimage-portlet,
  .sv-mediaflowVideo,
  .sv-embeddedmedia-portlet { 
    margin-bottom: 20px;
    & img {
      border-radius: @border-radius-normal;
    }
  }

  .sv-mediaflowVideo {
    iframe {
      border-radius: @border-radius-normal;
    }

    & > div > div > div {
      background: none !important;
    }
  }

  @media @md {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  @media @sm {
    padding-top: 10px;
    padding-bottom: 25px;
  }
  @media @xs {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.sol-wide-content-wrapper {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}
.sol-article-wrapper,
.sol-subpage-wrapper:not(.sol-landing-page) {
  & .sv-image-portlet, .sv-portlet, .sv-decoration-content {
    .sv-portlet-image-caption {
      margin-top: 0;
      padding: 8px 5px;
      background-color: @img-text-bg;
      color: @img-text;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      font-family: @main-font;
      font-weight: 400;
    }
  }

  .sv-script-portlet {
    margin-bottom: 0;
  }

  & .sv-linkicon {
    display: none;
  }
}
