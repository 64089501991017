.sol-mobile-search {
  display: none;
}

.sol-tool-nav-item .sol-js-toggle-search {
  display: none;
  @media only screen and (max-width: 991px) and (min-width: 275px) {
    display: block;
    margin-left: 30px;

    i.fa-search {
      margin-bottom: 10px;
      margin-top: 1px;
    }
  }

  @media only screen and (max-width: 275px) {
    @media @sm {
      display: block;
      margin-left: 19px;
  
      i.fa-search {
        margin-bottom: 10px;
      }
    }
  }

  i.fa-search {
    @media only screen and (max-width: 410px) and (min-width: 370px) {
      font-size: 25px;
    }
  
    @media only screen and (max-width: 370px) and (min-width: 350px) {
      font-size: 20px; 
    }
  
    @media only screen and (max-width: 350px) {
      font-size: 17px;  
    }
  }

  

}

.sol-tool-nav-item .sol-js-show-box {

  @media @sm {
    margin-left: -8px;
  }

  i.fal,
  i.fas,
  i.fab {
    margin-bottom: 10px;

    @media only screen and (max-width: 410px) and (min-width: 370px) {
      font-size: 25px;
    }

    @media only screen and (max-width: 370px)and (min-width: 350px) {
      font-size: 20px;
    }

    @media only screen and (max-width: 350px) {
      font-size: 17px;
    }
  }

}
