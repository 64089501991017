.sol-contact-card {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  padding: 20px 20px 10px;
  > .sv-text-portlet {
    background: @button-bg;
  }
  & .sv-text-portlet {
    //border-bottom: 1px solid @gray-2;
    margin-bottom: 10px;
    min-height: 51px;
    .sv-portlet-toolbar {
      font-weight: 600;
    }
  }
  & h1.font-heading-1 {
    .font-normal;
    font-weight: normal;
  }
  & h2.font-heading-2 {
    .font-heading-4;
  }
  & h1.font-heading-1,
  & h2.font-heading-2,
  & h3.font-heading-3,
  & h4.font-heading-4,
  & p.font-normal {
    margin: 0;
  }
  .sv-html-portlet {
    display: none;
    p {
      b {
        font-weight: 700;
      }
    }
    .sv-editing-mode & {
      display: block;
    }
  }

  .pagecontent {
    background-color: tint(@bg-gray,50%);
    margin: -20px -20px -20px -20px;
    padding: 20px 20px 10px;
    //box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .sv-image-portlet,
  .sv-mfpimage-portlet {
    img {
      width: 115px !important;
    }
  }

  > .sv-text-portlet {
    margin: -20px -20px 20px -20px;
    padding: 20px 20px 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white;

    h1.font-heading-1 {
      color: white;
    }
  }
}
