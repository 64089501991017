.sol-mobile-header {
  display: none;
  text-align: center;
  background-color: @header-top-bg;
  & > div {
    position: relative;
    max-width: 728px;
    height: 55px;
    margin-left: auto;
    margin-right: auto;
    @media @sm {
      height: 55px;
    }
  }
  @media @md {
    display: block;
  }
  @media @md-strict {
    background-color: @header-menu-bg;
    .sol-mobile-logo {
      display: none;
    }
  }
  .sol-mobile-logo {
    float: left;
    margin-left: 15px;
    margin-top: 11px;
    height: 30px;
  }
}
.sol-hamburger-wrapper {
    .focus-outline;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: @hamburger-menu-btn-bg;
    border: 0;
    text-align: center;
    padding-top: 43px;
    z-index: 9;
    &:hover {
      cursor: pointer;
    }
    @media only screen and (min-width: 992px){
      display: none;
    }
    & .sol-hamburger {
      width: 40px;
      position: absolute;
      top: 20px;
      left: 50%;
      margin-left: -20px;
      & > span {
        display: block;
        width: 40px;
        height: 2px;
        background-color: @hamburger-menu-btn-color;
        transition: transform 500ms;
      }
      & .sol-hamburger-middle {
        margin-top: 8px;
        margin-bottom: 8px;
        transition: width 500ms;
      }
      @media @sm {
        right: 10px;
      }
      &.sol-active {
        .sol-hamburger-top {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0px);
          width: 38px;
          top: 1px;
          position: relative;
        }
        .sol-hamburger-middle {
          width: 0;
        }
        .sol-hamburger-bottom {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0px);
          width: 38px;
        }
      }
    }
    & .sol-hamburger-text {
      color: @hamburger-menu-text-color;
      font-size: 14px;
      font-weight: 400;
      font-family: @heading-font;
      letter-spacing: 1px;
    }
  }
