#sol_contact_modal {
  &_header, &_footer { 
    .sol-modal-close {
      font-size: 12px;
      text-transform: uppercase;
      color: @white;
      font-family: @main-font;
      .transition(color);
      &:hover {
        color: fadeout(@white, 20%);
      }
      i.fal {
        display: block;
        .transition(color);
      }
    }
  }
}
