.sol-decoration-links {
    margin-bottom: 30px;
    padding: 10px 30px 20px 30px;
    box-shadow: none !important;
    & p > a {
      display: inline-block;
      position: relative;
      width: 100%;
      color: @color-3;
      padding-left: 30px;
      margin-bottom: 5px;
      text-decoration: none;
      &:first-of-type {
        margin-top: 10px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &:hover {
        text-decoration: underline;
      }
      &::before {
        .icon(@fa-var-angle-right, 22px);
        .fal;
        position: absolute;
        left: 0;
        top: 2px;
        text-align: center;
        padding-left: 2px;
        width: 20px;
        z-index: 8;
        background-color: @color-3;
        color: @white;
        border-radius: 50%;
        @media @sm {
          top: 3px;
        }
      }
    }

    & .sv-linkicon {
      position: absolute;
      top: 6px;
      left: 0;
      margin-left: 0;
      height: 18px;
      z-index: 10;
      background-color: @white;
      @media @sm {
        top: 4px;
      }
    }
    & .sol-external-link-icon {
      position: relative;
      top: -1px;
    }
  }
