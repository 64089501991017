.sol-event-listing-page {
  .sol-event-search {
    .sol-top {
      .sol-flex-row {
        @media @sm {
          padding-left: 4%;
          padding-right: 4%;
        }
      }
    }
    .sol-event-listing-page__create-event-btn {
      margin-top: 38px;
      max-width: 570px;
      border-radius: 100px;
      webkit-appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border: 0;
      background-color: @event-tipsa-bg;
      padding: 8px 28px;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      height: 50px;
      text-align: left;
      position: relative;
      cursor: pointer;
      line-height: normal;
      overflow: visible;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
      transition-property: background-color;
      transition-duration: 200ms;
      transition-timing-function: ease;
      font-size: 20px;
      line-height: 1.8;
      text-decoration: none;
      margin-left: 103px;
      @media @md-strict {
        margin-left: 0;
        width: 100%;
        max-width: none;
      }
      @media @sm {
        width: auto;
        max-width: none;
        margin-left: 4%;
        margin-right: 4%;
      }
      &:hover {
        text-decoration: underline;
        background-color: lighten(@event-tipsa-bg, 5%);
      }
      .sol-icon__tipsa {
        margin-right: 12px;
        .icon-size(27.6px, 29.5px);
      }
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        position: absolute;
        width: 40px;
        height: 40px;
        right: 4px;
        top: 50%;
        border-radius: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        .sol-icon {
          display: flex;
          .icon-size(17px, 19px);
        }
      }
    }
  }
}
