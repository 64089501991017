.sol-event-listing-page {
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  @media @md-strict {
    width: 79%;
  }
  @media @sm {
    max-width: 100%;
  }
  .font-heading-1 {
    @media @sm {
      margin-left: 4%;
      margin-right: 4%;
    } 
  }
  &__highlighted-event {
    &__list {
      display: flex;
      flex-wrap: wrap;
      @media @md {
        flex-direction: column;
      }
      @media @sm {
        padding-left: 4%;
        padding-right: 4%;
      }
    }
    &__item {
      text-decoration: none;
      flex-basis: 32%;
      flex-grow: 0;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: 25px;
      border-radius: 3px;
      overflow: hidden;
      cursor: pointer;
      @media @md {
        max-height: 130px;
      }
      @media @sm {
        max-height: 100%;
      }
      &:hover, &:active {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        .sol-event-listing-page__highlighted-event__item__content__title {
          text-decoration: underline;
        }
      }
      &:nth-child(3n+2) {
        margin-left: 1.5%;
        margin-right: 1.5%;
        @media @md {
          margin-left: 0;
          margin-right: 0;
        }
      }
      &__image {
        height: 200px;
        background-position: 50% 50%;
        background-size: cover;
        @media @md {
          height: 130px;
          width: 33%;
          float: left;
        }
        @media @sm {
          height: 200px;
          width: 100%;
          float: none;
        }
      }
      &__content {
        background-color: @white;
        padding: 17px 25px 31px 21px;
        min-height: 155px;
        @media @md {
          width: 67%;
          float: left;
        }
        @media @sm {
          width: 100%;
          padding-top: 16px;
          float: none;
        }
        &__date {
          > span {
            // font-family: @light-font;
            font-size: 17px;
            line-height: 21px;
            font-weight: 300;
            color: #313131;
            margin-bottom: 4px;
          }
        }
        &__title {
          font-size: 21px;
          text-align: left;
          color: #204560;
        }
      }
    }
  }
}
