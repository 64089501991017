.sol-error-page {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: @error-bg;
  text-align: center;

  &-text-big {
    font-size: 150px;
    line-height: 140px;
    font-weight: 700;
  }

  &-text-small {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 34px;
    line-height: 40px;
    font-weight: 300;
  }

  &-text-smaller {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }

  & .sol-btn {
    margin-top: 20px;

    &:first-child {
      margin-right: 10px;
    }
  }

  .sv-portlet {
    margin-bottom: 20px;
  }

  .sv-searchform-portlet {
    border: 1px solid #e7e7e7;
    display: block !important;
  }

  .sv-form-portlet {
    text-align: left;
  }
  .sol-top-search-field button[type=submit] {
    > i.fal {
      @media @sm {
        top: 7px;
        padding-bottom: 13px;
      }
    }
  } 
}

.sol-404-page {
  .sv-searchform-portlet {
    display: block !important;

    form {
      width: 70%;
      float: right;
      border: 1px solid #e7e7e7;
      margin-bottom: 90px;

      @media @md {
        width: initial;
        float: initial;
        margin-bottom: 10px;
      }

      input, button {
        height: 50px;
        font-size: 20px;
      }
    }
  }

  .font-btn {
    margin: 0;
    padding: 0;
    
    a {
      width: 70%;
      float: left;
      height: 50px;
      font-size: 20px;
      line-height: 33px;

      @media @md {
        width: initial;
        float: initial;
      }
    }
  }
}
