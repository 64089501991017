.sol-tool-nav {
  float: right;
  margin-top: -4px;
  position: relative;

  @media @md {
    display: inline-block;
    vertical-align: top;
    float: none;
    margin-top: 0;
    margin-left: 55px;
  }

  &-item {
    display: block;
    float: left;
  
    & > a,
    & > button {
      .focus-outline;
      .no-appearance;
      margin-left: 10px;
      font-weight: 400;
      color: @header-top-text;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      font-family: @main-font;
      display: block;

      @media @md {
        display: none;
      } 

      @media @sm {
        color: @header-top-mobile-text;
        margin: 0 9px 9px;
      }

      & i.fal {
        color: @alvdalen-purpur;
        font-size: 30px;
        display: block;
        text-align: center;
        margin-bottom: 10px;
      }
    }

    & .sol-close {
      .focus-outline;
      .no-appearance;
      position: absolute;
      top: 0;
      right: 0;
      color: @header-top-text;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      padding: 0;

      & i.fal {
        font-size: 11px;
      }
    }

    .sol-translate-link {
      background-color: @button-bg;
      color: @white;
      margin-top: 10px;
      padding: 8px;
      width: 100%;
    }

    #google_translate_element {
      margin-top: 15px;
      border-top: 1px solid lightgrey;
      padding-top: 15px;
    }
  }

  &-box {
    position: absolute;
    width: 189px;
    margin-top: 10px;
    margin-left: -60px;
    padding: 17px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .2);
    text-align: center;
    z-index: 1;
    cursor: default;

    @media @md-strict {
      margin-left: 0;
      right: 45px;
    }

    @media @sm {
      left: 50%;
      margin-left: -109px;
      width: 215px;
      padding: 12px;
    }

    & a.sol-btn-round {
      .transition(border);
      float: left;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      color: @header-top-box-share-icon;
      border: 2px solid @header-top-box-share-border;
      border-radius: 50%;
      font-size: 15px;
      padding-top: 1px;
      text-align: center;

      @media @sm {
        width: 40px;
        height: 40px;
        font-size: 21px;
        padding-top: 0;
        line-height: 37px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border-color: @header-top-box-share-hover;
      }
    }

    &-translate {
      padding: 12px 17px 9px;
      width: 265px;
      margin-left: -140px;
      box-shadow: 1px 5px 5px 0 rgba(0, 0, 0, .2);
      margin-top: 5px;

      @media @md {
        right: -60px;
        left: unset;
      }

      @media @sm {
        margin-top: -3px;
      }

      p {
        margin-bottom: 15px;
      }

      a {
        padding-bottom: 15px;
        display: block;
        margin: auto;
        width: max-content;
        text-decoration: none;
        text-align: center;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// READSPEAKER
.rsbtn {
  position: relative;
  display: inline-block;
  z-index: 10010;
  top: 300px;
  width: 355px!important;
  padding: 0px !important;

  & > a {
    display: none !important;
  }

  .rsbtn_tooltoggle {
    opacity: 0;
  }

  .rsbtn_dragbar {
    display: none !important;
  }
}

.rsexpanded {
  width: 100% !important;
  position: fixed !important;

  & > a {
    display: block !important;
  }

  .rsbtn_tooltoggle {
    opacity: 1;
  }

  .rsbtn_dragbar {
    display: block !important;
  }
}

//hamburger meny wrapper for mega menu

.sol-hamburger-megamenu-wrapper {
  .focus-outline;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: @hamburger-menu-btn-bg;
  border: 0;
  text-align: center;
  padding-top: 43px;
  z-index: 9;
  &:hover {
    cursor: pointer;
  }
  @media @md{
    display: none;
  }
  & .sol-hamburger-megamenu {
    width: 40px;
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -20px;
    & > span {
      display: block;
      width: 40px;
      height: 2px;
      background-color: @hamburger-menu-btn-color;
      transition: transform 500ms;
    }
    & .sol-hamburger-megamenu-middle {
      margin-top: 8px;
      margin-bottom: 8px;
      transition: width 500ms;
    }
    &.sol-active {
      .sol-hamburger-megamenu-top {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0px);
        width: 38px;
        top: 1px;
        position: relative;
      }
      .sol-hamburger-megamenu-middle {
        width: 0;
      }
      .sol-hamburger-megamenu-bottom {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0px);
        width: 38px;
      }
    }
    @media @sm {
      right: 10px;
    }
  }
  & .sol-hamburger-megamenu-text {
    color: @hamburger-menu-text-color;
    font-size: 14px;
    font-weight: 400;
    font-family: @heading-font;
    letter-spacing: 1px;
  }
}
