/* lessfile: articles/listing.less */
.sol-article-listing ul {
  .no-list-style;
}

.sol-article-listing-result {
  position: relative;
  &__spinner {
    //position: absolute;
    //left: 50%;
    //transform: translateX(-50%);
  }
}
