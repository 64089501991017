.rspkr_dr_link {
    background-color: transparent!important;
    padding-left: 0px!important;
    img {
      position: relative !important;
    }
  }
  
  .sv-image-portlet {
    .rspkr_dr_link {
      top: -25px;
      position: relative;
      background-color: @white !important;
      border: 2px solid @black;
      border-radius: 5px;
  
      &:hover {
        opacity: 0.7;
      }
  
      img {
        width: 16px;
      } 
    }
  }